import React from "react";
import { ScrollView, Switch, Text, View } from "react-native";
import { useSelector } from "react-redux";
import useUpdateAffiliate from "../useUpdateAffiliate";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";

const VcardManagement = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const [data, setData] = React.useState({
        vcardApproval: affiliate?.vcardApproval || false,
        vcardEmail: affiliate?.vcardEmail || '',
        vcardCompanyName: affiliate?.vcardCompanyName || '',
        vcardCompanyNameEditable: affiliate?.vcardCompanyNameEditable || false,
        vcardOutboundMessage: affiliate?.vcardOutboundMessage || '',
        vcardApprovalMessageEnabled: affiliate?.vcardApprovalMessageEnabled || false,
        vcardApprovalMessage: affiliate?.vcardApprovalMessage || '',
        vcardRejectMessageEnabled: affiliate?.vcardRejectMessageEnabled || false,
        vcardRejectMessage: affiliate?.vcardRejectMessage || '',
    })


    return (
        <ScrollView contentContainerStyle={{ flex: 1, gap: 10 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>VCard Approval</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>If you want changes to vCards to go through an approval process, then turn on vCard approval here and type in an email address for the person who will be responsible for vCard approval</Text>
            <Switch
                onValueChange={e => setData({ ...data, vcardApproval: e })}
                value={data.vcardApproval}
            />
            <CustomInput
                onChangeText={e => setData({ ...data, vcardEmail: e })}
                value={data.vcardEmail}
                title={'VCard Approval Email'}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Company Name</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>Set the default company name that you want to have pre-filled on all new vCards for the accounts within your group. You can also set the can be changed toggle to off if you don't want the users in the accounts to be able to change this company name.</Text>
            <CustomInput
                onChangeText={e => setData({ ...data, vcardCompanyName: e })}
                value={data.vcardCompanyName}
                title={'Company Name'}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>Can be changed?</Text>

            <Switch
                onValueChange={e => setData({ ...data, vcardCompanyNameEditable: e })}
                value={data.vcardCompanyNameEditable}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Accompanying Text</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>Include the following text on the outbound message when a VCard is sent.</Text>
            <CustomInput
            onChangeText={e => setData({ ...data, vcardOutboundMessage: e })}
            value={data.vcardOutboundMessage}
            multiline
            textInputStyle={{minHeight:100}}
            title={'Outbound Message'}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>VCard Approval Email</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>If enabled, an email with this text is sent to the account holder when an administrator approves the vcard.</Text>
            <Switch
                onValueChange={e => setData({ ...data, vcardApprovalMessageEnabled: e })}
                value={data.vcardApprovalMessageEnabled}
            />
            <CustomInput 
            onChangeText={e => setData({ ...data, vcardApprovalMessage: e })}
            value={data.vcardApprovalMessage}
            multiline
            textInputStyle={{minHeight:100}}
            title={'Approval Message'}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>VCard Reject Email</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>If enabled, an email with this text is sent to the account holder when an administrator rejects the vcard.</Text>
            <Switch 
            onValueChange={e => setData({ ...data, vcardRejectMessageEnabled: e })}
            value={data.vcardRejectMessageEnabled}
            />
            <CustomInput
            textInputStyle={{minHeight:100}}
            onChangeText={e => setData({ ...data, vcardRejectMessage: e })}
            value={data.vcardRejectMessage}
            multiline
            title={'Reject Message'}
            />

            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end', marginTop: 20, marginBottom: 10, marginRight: 10 }}
            />


        </ScrollView>
    )
}

export default VcardManagement;