import React from "react";
import { ScrollView, Switch, Text, View } from "react-native";
import CustomInput from "../../Components/CustomInput/index.web";
import AppFonts from "../../Constants/Fonts";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import SimpleDropdown from "../../Components/SimpleDropdown";
import CustomButton from "../../Components/Button/index.web";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const AddDisclosure = ({item, onSave}) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const {data, isLoading} = getQuery({
        action: 'get_tenants',
        group: affiliate.id,
    })
    const Toast = useToast();

    const [disclosure, setDisclosure] = React.useState({
        name: item.name,
        active: item.active,
        name: item.name,
        text: item.text,
        frequencyType: item.frequencyType,
        frequencyStart: item.frequencyStart,
        frequencyValue: item.frequencyValue,
        frequencyUnit: item.frequencyUnit,
        conditionType: item.conditionType,
        conditionValue: item.conditionValue,
        append: item.append,
    })

    React.useEffect(()=>{setDisclosure(item)},[item])

    if(!data) return null;
    if(isLoading) return <Loader backgroundColor={'transparent'} />

    const states = [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' }
    ];

    const handleSave = async () => {
        try{
        const body = {
            ...item,
            ...disclosure,
            affiliateId: affiliate.id,
        }

        if(item.id){
            body.id = item.id;
            body.action = 'update_disclosure';
        } else {
            body.action = 'create_disclosure';
        }

        const result = await RequestMaker(body);
        if(result.data.success){
            onSave()
            Toast.show({
                status:'success',
                title: 'Disclosure Saved'
            })
        } else if(result.data.errorMessage){
            Toast.show({
                status:'error',
                title: result.data.errorMessage
            })
        }
        } catch(e){
            console.log(e)
        }

    }
    

    return (
        <ScrollView contentContainerStyle={{padding:15,flex:1}}>
            <CustomInput value={disclosure.name} onChangeText={val => setDisclosure({...disclosure, name: val})} title={'Name'}/>
            <CustomInput multiline textInputStyle={{minHeight:100}} value={disclosure.text} onChangeText={val => setDisclosure({...disclosure, text: val})}  titleStyle={{marginTop:10}} title={'Disclosure Text'}/>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>Enabled</Text>
            <Switch value={disclosure.active} onValueChange={val => setDisclosure({...disclosure, active: val})}/>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>Send As</Text>
            <SimpleCheckbox value={!disclosure.append} onChange={val => setDisclosure({...disclosure, append: false})} label={'Separate Message'}/>
            <SimpleCheckbox value={disclosure.append} onChange={val => setDisclosure({...disclosure, append: true})} label={'Append to Message'}/>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
            How frequently do you want to send the same contact this disclosure?
            </Text>
            <SimpleDropdown 
                onChange={val => setDisclosure({...disclosure, frequencyType: val})}
                value={disclosure.frequencyType}
                data={[
                    {
                        label: 'Once',
                        value: 'once'
                    },
                    {
                        label: 'Always',
                        value: 'always'
                    },
                    {
                        label: 'Time Based',
                        value: 'time'
                    }
                ]}
            />
            {
                disclosure.frequencyType === 'time' && 
                <>
                <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
                Every
                </Text>
                <View style={{flexDirection:'row',alignItems:'center'}}>
                <CustomInput value={disclosure.frequencyValue} onChangeText={ val => {
                        setDisclosure({...disclosure, frequencyValue: val})
                    
                } } containerStyle={{height:'100%',marginTop:0}} placeholder={'1-30'} width={'15%'}/>
                <SimpleDropdown 
                style={{height:'100%',minWidth:150,marginLeft:5}}
                onChange={val => setDisclosure({...disclosure, frequencyUnit: val})}
                value={disclosure.frequencyUnit}
                data={[
                    {
                        label: 'Days',
                        value: 'day'
                    },
                    {
                        label: 'Months',
                        value: 'month'
                    },
                    {
                        label: 'Years',
                        value: 'year'
                    }
                ]}/>
                </View>
                <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
                Starting
                </Text>
                <SimpleDropdown
                data={[
                    {
                        label: 'Immediately',
                        value: 'immediately'
                    },
                    {
                        label: 'After Initial Time',
                        value: 'aftertime'
                    }
                ]}
                value={disclosure.frequencyStart}
                onChange={val => setDisclosure({...disclosure, frequencyStart: val})}
                />
                </>
            }
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
            Under what condition should this disclosure be sent?
            </Text>
            <SimpleDropdown
            value={disclosure.conditionType}
            onChange={val => setDisclosure({...disclosure, conditionType: val, conditionValue: ''})}
                data={[
                    {
                        label: 'Unconditionally',
                        value: 'none'
                    },
                    {
                        label: 'Specific Account',
                        value: 'tenant'
                    },
                    {
                        label: 'Number of Logins',
                        value: 'logins'
                    },
                    {
                        label: 'Location',
                        value: 'state'
                    },
                    {
                        label: 'Positive Consent',
                        value: 'consent'
                    },
                    {
                        label: 'VCard',
                        value: 'vcard'
                    }
                ]}
            />

            {
                disclosure.conditionType === 'logins' &&
                <View>
                <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
                    When the account has more than
                </Text>
                    <View style={{flexDirection:'row',alignItems:'flex-end'}}>
                        <CustomInput
                        maxLength={'2'} 
                        onChangeText={val =>{ 
                            if( !isNaN(val)) setDisclosure({...disclosure, conditionValue: val})}}
                        value={disclosure.conditionValue} placeholder="1-10" containerStyle={{alignSelf:'flex-start'}} width={'10%'} />
                        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginLeft:5}}>
                            logins.
                        </Text>
                    </View>
                </View>
            }
            {
                disclosure.conditionType === 'state' &&
                <>
                <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
                Located in the state
                </Text>

                <SimpleDropdown
                data={states}
                value={disclosure.conditionValue || 'AL'}
                onChange={val => setDisclosure({...disclosure, conditionValue: val})}
                />
                </>
            }

            {disclosure.conditionType === 'tenant' &&
            <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
            The account is
            </Text>
            <SimpleDropdown
            onChange={val => setDisclosure({...disclosure, conditionValue: String(val)})}
            data={data.tenants.map(e => ({label: e.contact, value: String(e.id)}))}
            value={disclosure.conditionValue}
            />

            </>
            }

            <View style={{flexDirection:'row',alignItems:'center',marginTop:10,justifyContent:'flex-end',marginTop:'auto'}}>
                <CustomButton onPress={handleSave} title={'Save'} width={'30%'} />
            </View>

        </ScrollView>
    )
}

export default AddDisclosure;